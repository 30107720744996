@if (config.breadcrumbs) {
    <div class="breadcrumb">
        @for (breadcrumb of config.breadcrumbs; track $index) {
            <a class="breadcrumb-item" [routerLink]="breadcrumb.route">{{ breadcrumb.label }}</a>
        }
        <a class="breadcrumb-item"></a>
    </div>
}

<h1>{{ config.header.title }}</h1>

@if (config.actions) {
    <div class="page-actions row">
        <div class="col-12 col-sm-6 form-group">
            @if (config.content.type === 'LIST' && config.content.search?.enabled) {
                <cm-input #searchInput [attr.placeholder]="'General.Search' | trans"
                          data-icons-left="search"
                          data-clearable (cm-input-clear-input)="onSearchClear()"></cm-input>
            }
        </div>
        <div class="col-12 col-sm-6 text-right">
            @if (config.actions.cta) {
                <cm-button
                    [attr.data-label]="config.actions.cta.label | trans"
                    data-button-type="icon-text"
                    data-icon="add"
                    data-button-style="cta"
                    [routerLink]="config.actions.cta.route">
                </cm-button>
            }
        </div>
    </div>
}

<ng-template pageContent></ng-template>
