import {
    Component, ComponentRef, ElementRef,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {ComponentPageConfig, ListPageConfig, PageConfig, PageContentConfig} from '../../config/page-config.type';
import {ActivatedRoute} from '@angular/router';
import {PageContentDirective} from '../../page-content.directive';
import {ListPageComponent} from '../list-page/list-page.component';
import {BusService} from '../../../message-bus/bus.service';
import {fromEvent} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
    selector: 'app-page',
    templateUrl: './page.component.html',
    styleUrl: './page.component.scss'
})
export class PageComponent {
    public config: PageConfig;

    @ViewChild(PageContentDirective, {static: true})
    set pageContentDirective(pageContentDirective: PageContentDirective) {
        this.viewContainerRef = pageContentDirective.viewContainerRef;
        this.renderContent(this.config.id, this.config.content);
    }

    @ViewChild('searchInput')
    set searchInput(searchInput: ElementRef) {
        if (!searchInput) {
            return;
        }

        this.initializeSearch(searchInput);
    }

    private viewContainerRef: ViewContainerRef;

    public constructor(private route: ActivatedRoute, private readonly bus: BusService) {
        this.config = this.route.snapshot.data.config;

        if (this.config.beforeInitialize) {
            this.config.beforeInitialize();
        }
    }

    private renderContent(id: string, config: PageContentConfig): void {
        switch (config.type) {
            case 'COMPONENT': {
                this.renderComponent(config);
                break;
            }
            case 'LIST': {
                this.renderList(id, config);
                break;
            }
            default: {
                throw new Error('Page content type not supported');
            }
        }
    }

    private renderComponent(config: ComponentPageConfig): void {
        this.viewContainerRef.createComponent(config.component);
    }

    private renderList(id: string, config: ListPageConfig): void {
        const componentRef: ComponentRef<ListPageComponent> = this.viewContainerRef.createComponent(ListPageComponent);
        componentRef.setInput('pageId', id);
        componentRef.setInput('config', config);
    }

    private initializeSearch(searchInput: ElementRef): void {
        fromEvent(searchInput.nativeElement, 'input')
            .pipe(
                debounceTime(300),
            )
            .subscribe({
                next: (e: any) => {
                    this.bus.emit({
                        id: 'LIST_PAGE.SEARCH',
                        details: {
                            search: e.target.value
                        }
                    });
                }
            });
    }

    public onSearchClear(): void {
        this.bus.emit({
            id: 'LIST_PAGE.SEARCH',
            details: {
                search: undefined
            }
        });
    }
}
