import {ChangeDetectionStrategy, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ListPageConfig, ListPageRow, ListRowAction} from '../../config/page-config.type';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {map, switchMap, take, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {BusEvent, BusService} from '../../../message-bus/bus.service';
import {DialogComponent} from '../../../shared/components/dialogs/dialog/dialog.component';

@Component({
    selector: 'app-list-page',
    templateUrl: './list-page.component.html',
    styleUrl: './list-page.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListPageComponent implements OnInit {

    @Input()
    pageId: string;

    @Input()
    config: ListPageConfig;

    @ViewChild('confirmActionDialog')
    confirmActionDialog: DialogComponent;

    private getSource: BehaviorSubject<void> = new BehaviorSubject<void>(null);
    public source$: Observable<any[]>;

    public listHasItems = false;
    public requiresPagination = false;

    public paginationTake = 10;
    public paginationSkip = 0;
    public paginationTotal: number = undefined;

    public sourceSearch: string | undefined;

    private confirmActionSave$: Subscription;

    constructor(private readonly http: HttpClient, private router: Router, private bus: BusService) {
    }

    ngOnInit(): void {
        const mapFn = this.config.source.mapFn;

        this.bus.on('LIST_PAGE.REFRESH', () => {
            this.paginationSkip = 0;
            this.getSource.next();
        });

        this.bus.on('LIST_PAGE.SEARCH', (e: BusEvent) => {
            this.sourceSearch = e.details.search;
            this.paginationSkip = 0;
            this.getSource.next();
        });

        this.source$ = this.getSource.pipe(
            switchMap(() => {
                if (this.config.source.type === 'ASYNC') {
                    let httpParams = new HttpParams()
                        .set('skip', this.paginationSkip)
                        .set('take', this.paginationTake);

                    if (this.sourceSearch && this.config.search.enabled) {
                        httpParams = httpParams.set(this.config.search.parameter, this.sourceSearch);
                    }

                    return this.http.get(environment.CM_API_URL + '/seatedapi' + this.config.source.endpoint, {
                        withCredentials: true,
                        params: httpParams,
                        observe: 'response'
                    }).pipe(
                        tap((response: HttpResponse<unknown[]>) => {
                            this.paginationTake = +response.headers.get('X-CM-PAGINATION-TAKE');
                            this.paginationSkip = +response.headers.get('X-CM-PAGINATION-SKIP');
                            this.paginationTotal = +response.headers.get('X-CM-PAGINATION-TOTAL');
                        }),
                        map((response: HttpResponse<unknown[]>) => response.body.map(mapFn)),
                        tap((response: unknown[]) => {
                            this.listHasItems = response.length > 0;
                            this.requiresPagination = true;
                        })
                    );
                } else {
                    this.listHasItems = this.config.source.data.length > 0;
                    this.requiresPagination = false;

                    return of(this.config.source.data.map(mapFn));
                }
            })
        );

        this.getSource.next();
    }

    public onRowClick(row: ListPageRow): void {
        if (!row.route) {
            return;
        }

        return void this.router.navigate(row.route);
    }

    public onPaginationChange(e: PaginationChangeEventDetails): void {
        this.paginationSkip = e.skip;
        this.paginationTake = e.take;

        this.getSource.next();
    }

    onActionClick(action: ListRowAction) {
        if (this.confirmActionSave$) {
            this.confirmActionSave$.unsubscribe();
        }

        if (action.confirm?.isRequired) {
            this.confirmActionDialog.title = action.label;
            this.confirmActionDialog.body = action.confirm.message;
            this.confirmActionDialog.open();

            this.confirmActionSave$ = this.confirmActionDialog.save.pipe(take(1)).subscribe({
                complete: () => {
                    this.bus.emit({id: this.pageId + '.' + action.id, details: action.details});
                }
            });

            return;
        }

        this.bus.emit({id: this.pageId + '.' + action.id, details: action.details});
    }
}

type PaginationChangeEventDetails = {
    skip: number,
    take: number
};
