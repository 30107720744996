@if (source$ | async; as list) {
    @if (listHasItems) {
        <div class="list-group m-t-24 example row col-12">
            @for (row of list; track row.id) {
                <div class="list-group-content row" (click)="onRowClick(row)">
                    @for (column of (row.columns | objectValues); track $index) {
                        <div class="table-column truncate" [ngClass]="$index === 0 ? 'col-5' : 'col'" [class.list-column-text-right]="column.textAlign === 'right'">
                            <span [innerHTML]="(column.formattedValue | byPassSecurity) ?? column.value"></span>
                        </div>
                    }

                    <div class="table-column col-1 list-column-text-right">
                        @if (row.actions?.length > 0) {
                            <cm-context-menu>
                                @for (action of row.actions; track action.id) {
                                    <cm-option [attr.data-icon]="action.icon" [attr.data-display]="action.label"
                                               (click)="onActionClick(action)"></cm-option>
                                }
                            </cm-context-menu>
                        }
                    </div>
                </div>
            }
        </div>

        @if (requiresPagination) {
            <cm-pagination
                [attr.data-total]="paginationTotal"
                [attr.data-take]="paginationTake"
                [attr.data-skip]="paginationSkip"
                data-show="3"
                data-show-totals
                data-show-page-size
                (change)="onPaginationChange($any($event).detail)">
            </cm-pagination>
        }
    } @else {
        <div class="empty-state">
            <cm-image data-type="empty" data-min-height="200"></cm-image>
        </div>
    }
}

<app-dialog #confirmActionDialog [scope]="'CONFIRM'"></app-dialog>
